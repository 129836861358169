





















































































































































































































































































































































import VueBase from '@/VueBase'
import { Component, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Trend from '../dashboard/components/trend.vue'
import Project from '../dashboard/components/project.vue'
import Distribution from '../project/components/distribution.vue'
import DistributionRound from '../project/components/distribution-round.vue'
import TypeBar from '../project/components/typeBar.vue'
import { returnFloat1 } from '@/utils/utils'

@Component({
  name: 'Team',
  components: {
    Trend,
    Project,
    TypeBar,
    Distribution,
    DistributionRound,
  },
})
export default class Team extends VueBase {
  async goPath(name: string, query: any) {
    this.$router.push({ name: name, query })
  }
  // todo 解除应用关联
  private handleEditApp(row: any) {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '确定解除关联',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定解除当前应用和当前项目的关联关系吗?
        </div>
        <div class="tip">
          解除关联即为解散当前应用跟项目关系，不会删除项目内的应用。
        </div>
      `,
    }).then(async () => {
      const projectId = this.curProjectGroup
      const applicationId = row.id
      const res = await this.services.project.unbindProjects({
        project_id: projectId,
        application_id: applicationId,
      })
      if (res.status === 201) {
        // 解除关联成功，重新刷新当前应用列表
        this.p_page = 1
        this.p_pageSize = 10
        this.p_alltotal = 0
        this.getProjects(undefined, this.curProjectGroup)
      }
    })
  }
  private getLevelInfo(level_id: any) {
    switch (level_id) {
      case 1:
        return {
          className: 'height',
          levelName: '高危',
        }
      case 2:
        return {
          className: 'middle',
          levelName: '中危',
        }
      case 3:
        return {
          className: 'low',
          levelName: '高危',
        }
      case 5:
        return {
          className: 'info',
          levelName: '提示',
        }
    }
  }
  private getLevel(type: number) {
    switch (type) {
      case 1:
        return 'height'
      case 2:
        return 'middle'
      case 3:
        return 'low'
      case 5:
        return 'info'
      default:
        break
    }
  }
  data: any = {}
  now = new Date()
  lastWeek = new Date(this.now.getTime() - 7 * 24 * 3600 * 1000)
  times = [this.lastWeek, this.now]
  type = '3'
  active = '7'
  private searchDate: any = ''

  private levelMap: any = {
    高危漏洞: {
      level_id: 4,
      level_name: '高危',
    },
    中危漏洞: {
      level_id: 3,
      level_name: '中危',
    },
    低危漏洞: {
      level_id: 2,
      level_name: '低危',
    },
    提示信息: {
      level_id: 1,
      level_name: '提示',
    },
  }

  private mapName: any = {
    严重: '高危',
    高危: '中危',
    中危: '低危',
    低危: '提示',
    无风险: '无风险',
  }
  async changeTime() {
    this.active = '1'
    this.getDashboard()
  }

  async changeActive() {
    // this.active = type
    const type = this.searchDate
    this.times = [
      new Date(this.now.getTime() - type * 24 * 3600 * 1000),
      this.now,
    ]
    this.getDashboard()
  }
  async getDashboard() {
    this.loadingStart()
    let timestamp_end = 0
    if (this.active === '1') {
      timestamp_end = Math.floor(this.times[1].getTime() / 1000) + 60 * 60 * 24
    } else {
      timestamp_end = Math.floor(this.times[1].getTime() / 1000)
    }
    const res = await this.services.project.dashboardByProjectGroup(
      {
        timestamp_begin: Math.floor(this.times[0].getTime() / 1000),
        timestamp_end: timestamp_end,
        type: this.type,
      },
      this.curProjectGroup
    )
    this.loadingDone()
    this.data = res.data || {}
    let valueTotal = 0
    this.data.level_count = this.data.vul_group_by_level.map((item: any) => {
      valueTotal += item.level__name_type__count
      return {
        num: item.level__name_type__count,
        level_name: this.levelMap[item.level__name_type].level_name,
        level_id: this.levelMap[item.level__name_type].level_id,
      }
    })
    this.data.level_count.forEach((item: any) => {
      item.level_total_percentage = returnFloat1(item.num / valueTotal)
    })
    this.data.level_count.sort((a: any, b: any) => {
      return b.level_id - a.level_id
    })

    let typeTotal = 0
    this.data.type_summary = this.data.vul_type_top_n.map((item: any) => {
      typeTotal += item.count
      return {
        type_name: item.strategy__vul_name,
        type_count: item.count,
        type_level: item.strategy__level_id,
      }
    })
    this.data.type_summary.forEach((item: any) => {
      item.type_total_percentage = returnFloat1(item.type_count / typeTotal)
    })

    let scaTotal = 0
    this.data.sca_count_echart = this.data.sca_count_with_vul.map(
      (item: any) => {
        scaTotal += item.level__name_type__count
        return {
          num: item.level__name_type__count,
          // level_name: this.mapName[item.level__name_type],
          level_name: item.level__name_type,
          level_id: item.level,
        }
      }
    )

    this.data.sca_count_echart.forEach((item: any) => {
      item.level_total_percentage = returnFloat1(item.num / valueTotal)
    })
    this.data.sca_count_echart.sort((a: any, b: any) => {
      return b.level_id - a.level_id
    })
    this.data.project_vul_top_n = this.data.project_vul_top_n?.slice(0, 5)
  }

  private curKey: any = 'dashbord'
  private projects: any = []
  private chooseApps: any = []
  private tableData = {
    data: [],
    currentPage: 1,
    pageSize: 200,
    total: 0,
  }

  private appTableData: any = []
  private curProjectGroup: any = ''
  private curProjectGroupItem: any = {}
  changeTab(currentKey: any) {
    this.curKey = currentKey
    if (currentKey == 'apps') {
      this.getProjects(undefined, this.curProjectGroup)
    } else {
      // todo 获取项目组的dashboard数据
      this.getDashboard()
    }
  }

  getProjectGroupApps(e: any, item: any) {
    e.stopPropagation()
    e.preventDefault()
    // todo 获取项目应用详情
    this.curProjectGroup = item.id
    const curProjectGroup: any = this.tableData.data.filter(
      (item1: any) => item1.id == item.id
    )
    if (curProjectGroup && curProjectGroup.length == 1) {
      // this.appTableData = curProjectGroup[0].projects
      this.curProjectGroupItem = curProjectGroup[0]
    }
    this.p_page = 1
    this.p_pageSize = 10
    this.p_alltotal = 0
    this.handleDetail(item)
    this.changeTab(this.curKey)
    // todo 获取项目组的dashboard数据
    this.getDashboard()
  }

  private notSelectAllApp: boolean = true

  selectAllApp() {
    if (this.notSelectAllApp) {
      this.notSelectAllApp = false
      this.projects = this.projects.map((obj: any) => {
        obj.checked = true
        return {
          ...obj,
        }
      })
      this.chooseApps = [...this.projects]
    } else {
      this.notSelectAllApp = true
      this.projects = this.projects.map((obj: any) => {
        obj.checked = false
        return {
          ...obj,
        }
      })
      this.chooseApps = []
    }
  }

  clearChooseApp() {
    this.notSelectAllApp = true
    this.chooseApps = []
    this.projects = this.projects.map((obj: any) => {
      obj.checked = false
      return {
        ...obj,
      }
    })
  }

  deleteChooseApp(item: any) {
    this.projects = this.projects.map((obj: any) => {
      if (obj.id === item.id) {
        if (item.checked) {
          obj.checked = false
          this.chooseApps = this.chooseApps.filter(
            (item1: any) => item1.id != item.id
          )
        }
      }
      return {
        ...obj,
      }
    })
  }

  changeProject(item: any) {
    this.projects = this.projects.map((obj: any) => {
      if (obj.id === item.id) {
        if (!item.checked) {
          obj.checked = false
          this.chooseApps = this.chooseApps.filter(
            (item1: any) => item1.id != item.id
          )
        } else {
          obj.checked = true
          this.chooseApps.push(obj)
        }
      }
      return {
        ...obj,
      }
    })
  }

  onSearchProjects(name: string) {
    this.projects = this.projects.filter(
      (obj: any) => obj.name.indexOf(name) > -1
    )
  }

  private formData: any = {
    name: '',
    projects: [],
    users: [],
  }
  private rules: any = {
    name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
    projects: [{ required: false, message: '请添加关联应用', trigger: 'blur' }],
    users: [{ required: true, message: '请添加项目负责人', trigger: 'blur' }],
  }

  private dialogTitle: string = ''
  private dialogVisible: boolean = false
  private id: any = ''
  private isLink: boolean = false
  handleAdd() {
    this.chooseApps = []
    this.dialogTitle = '新增'
    this.id = ''
    this.dialogVisible = true
    this.getProjects()
    this.getUsers()
  }
  async handleEdit(e: any, row: any, link: any) {
    e.stopPropagation()
    e.preventDefault()
    this.dialogTitle = '编辑'
    this.chooseApps = []
    this.id = row.id
    this.dialogVisible = true
    this.isLink = link ? true : false
    let { data, status } = await this.services.authority.getGroup(row.id)
    if (status !== 201) return
    this.formData.name = data.name
    // this.projects.unshift(...data.projects)
    // this.projects = this.unique(this.projects)
    this.options = this.unique([...data.users, ...this.options])
    // console.log('this.options', this.options)
    this.formData.projects = data.projects.map((item: any) => item.id)
    // this.formData.users = data.users.map((item: any) => item.id)
    this.formData.users = data.users.map((item: any) => item.id)?.[0]
    this.chooseApps = data.projects
    this.getProjects(undefined, undefined, true)
    this.getUsers()
  }

  unique(arr: any) {
    var result = arr.reduce((unique: any, o: any) => {
      if (!unique.some((obj: any) => obj.id === o.id)) {
        unique.push(o)
      }
      return unique
    }, [])
    return result
  }

  private detailVisible: boolean = false
  private detailData: any = {}
  async handleDetail(row: any) {
    this.detailData = {}
    this.detailVisible = true
    let { data, status } = await this.services.authority.getGroup(row.id)
    if (status !== 201) return
    const users: any = []
    if (data.user_projects) {
      for (let key in data.user_projects) {
        users.push(key)
      }
    } else {
      users.push(data.create_user.username)
    }
    this.detailData = {
      ...data,
      users: users,
    }
  }
  handleClose() {
    ;(this.$refs.ruleForm as any)?.resetFields()
    this.dialogVisible = false
  }

  handleDelete(e: any, row: any) {
    e.stopPropagation()
    e.preventDefault()
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除当前项目?
        </div>
        <div class="tip">
          删除即为解散当前项目，不会删除组内应用。
        </div>
      `,
    }).then(async () => {
      let { status } = await this.services.authority.deleteGroup(row.id)
      status === 201
        ? this.$message.success('项目删除成功')
        : this.$message.error('项目删除失败，请重试')
      if (row.id == this.curProjectGroup) {
        //  删除的是当前项目组
        this.$router.go(0)
        // this.getList(true)
      } else {
        // 删除的不是当前项目组
        this.getList()
        // this.curProjectGroup = row.id
      }
      // this.getList(true)
    })
  }
  resetAllState() {
    this.p_page = 1
    this.p_pageSize = 10
    this.p_alltotal = 0
    this.projects = []
    this.chooseApps = []
    this.tableData = {
      data: [],
      currentPage: 1,
      pageSize: 200,
      total: 0,
    }
    this.appTableData = []
    this.curProjectGroup = ''
    this.curProjectGroupItem = {}
    this.detailData = {}
    this.detailVisible = false
    this.curKey = 'dashbord'
    this.dialogTitle = ''
    this.isLink = false
    this.dialogVisible = false
    this.id = ''
    this.notSelectAllApp = true
    this.formData = {
      name: '',
      projects: [],
      users: [],
    }

    this.data = {}
    this.now = new Date()
    this.lastWeek = new Date(this.now.getTime() - 7 * 24 * 3600 * 1000)
    this.times = [this.lastWeek, this.now]
    this.type = '3'
    this.active = '7'
    this.searchDate = ''
  }
  created() {
    this.isLink = false
    this.getList(true).then((res: any) => {
      this.getDashboard()
    })
  }
  async getList(reload: boolean = false) {
    let { data, status, page } = await this.services.authority.getGroupList({
      page: this.tableData.currentPage,
      page_size: this.tableData.pageSize,
    })
    if (status !== 201) return
    this.tableData.data = data.map((item: any, index: number) => {
      // todo 初始化
      if (index == 0 && reload) {
        this.curProjectGroup = item.id
        this.curProjectGroupItem = item
        this.handleDetail(item)
        this.changeTab(this.curKey)
      }
      const users: any = []
      if (item.users&& item.users.length > 0) {
        item.users.map((user: any) => {
          users.push(user.username)
        })
      }

      return {
        ...item,
        offline:
          item.projects.filter((item: any) => item.status == 2).length || 0,
        yichang:
          item.projects.filter((item: any) => item.status == 1).length || 0,
        zhengchang:
          item.projects.filter((item: any) => item.status == 0).length || 0,
        group_users: users.join('、'),
      }
    })
    this.tableData.total = page.alltotal
  }

  // 这里是原来项目组的分页
  handleCurrentChange(val: any) {
    this.tableData.currentPage = val
    this.getList()
  }
  status: any = [
    {
      name: '正常',
      color: 'var(--color-success)',
      value: 0,
    },
    {
      name: '异常',
      color: 'var(--color-danger)',
      value: 1,
    },
    {
      name: '离线',
      color: '#6B7280',
      value: 2,
    },
  ]
  handleSubmit() {
    ;(this.$refs.ruleForm as any)?.validate(async (valid: boolean) => {
      if (valid) {
        // if (this.formData.projects.length < 2) {
        //   this.$message.warning('至少需关联两个项目')
        //   return
        // }
        let res: any = {}
        let msg: any = '创建'
        // projects
        const projectItems: any = []
        this.chooseApps.map((item: any) => {
          projectItems.push(item.id)
        })
        this.formData.projects = projectItems
        if (this.id) {
          msg = '编辑'
          res = await this.services.authority.editGroup(this.id, {
            ...this.formData,
            users:[this.formData.users]
          })
        } else {
          res = await this.services.authority.addGroup({
            ...this.formData,
            users:[this.formData.users]
          })
        }
        if (res.status !== 201) {
          this.$message.error(`项目${msg}失败，请重试`)
          return
        }
        this.$message.success(`项目${msg}成功`)
        this.handleClose()

        if (this.id) {
          //  todo编辑项目组
          if (this.curProjectGroup == this.id) {
            //  编辑的是当前项目组
            this.getList()
            this.curProjectGroup = this.id
            // 刷新项目详情、dashboard、应用列表
          } else {
            // 编辑的非当前项目组
            this.getList()
            // this.curProjectGroup = this.id
          }
        } else {
          //  todo新增项目组
          this.getList()
        }
        // this.getList()
      }
    })
  }
  formatterTime(time: any) {
    return moment(time * 1000).format('YYYY-MM-DD HH:mm')
  }
  private async remoteProject(query: string) {
    this.getProjects(query)
  }

  private p_page = 1
  private p_pageSize = 10
  private p_alltotal: any = 0

  handleSizeChange(val: number) {
    this.p_pageSize = val
    this.getProjects()
  }
  handleCurrentChange1(val: number) {
    this.p_page = val
    this.getProjects()
  }

  private async getProjects(
    name?: string | undefined,
    project_id?: string | undefined,
    all_project?: boolean
  ) {
    const res = await this.services.project.projectList({
      page: all_project ? 1 : this.p_page,
      pageSize: all_project ? 1000 : this.p_pageSize,
      name: name,
      project_id: project_id,
    })
    if (res.status === 201) {
      const data = res.data || []
      const page = res.page || {}
      res.data.map((item: any) => {
        data.push({
          ...item,
          checked: false,
        })
      })
      const myProjects = this.unique(data)
      const myChooseApps: any = []
      this.projects = myProjects.map((item: any) => {
        const res = this.chooseApps.filter((item1: any) => item1.id == item.id)
        if (res && res.length > 0) {
          item.checked = true
        }
        return item
      })
      // this.projects = this.unique(data)
      if (!all_project) {
        this.appTableData = this.unique(data)
        this.p_alltotal = page.alltotal
      }
    }
  }
  private async remoteUser(query: string) {
    this.getUsers(query)
  }
  private options: any = []
  private async getUsers(name?: string | undefined) {
    const res = await this.services.authority.getUserList({
      page: 1,
      pageSize: 50,
      name: name,
      is_global_permission: true,
    })
    if (res.status === 201) {
      this.options = res.data
    }
  }
}
